.btn {
   color: #000000;
   font-size: 15px;
   font-weight: 400;
   padding: 1rem 1.5rem;
   background-color: #FFFFFF;
   box-shadow: 4px 4px 0 0 #000000;
   text-decoration: none;
   display: inline-block;
   cursor: pointer;
   text-align: center;
}

.btn:hover {
   background: #FFFFFF;
   transform: scale(1.03);
}

.btn:active {
   transform: scale(1.00);
}