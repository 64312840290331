@tailwind base;
@tailwind components;
@tailwind utilities;

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	font-family: 'Press Start 2P', cursive;
	background-color: #F9F1E9;
	font-size: 15px;
	color: #000;
	font-weight: 400;
}
ol, ul {
	list-style: none;
}

h1 {
	font-size: 5rem;
	font-weight: 600;
}

h2 {
	font-size: 4rem;
    font-weight: 600;
}

h3 {
	font-size: 1.25rem;
}

p {
	line-height: 2;
}

a:hover {
	opacity: 0.8;
}

@media only screen and (max-width: 768px) {
	h1 {
		font-size: 3rem;
	}

	h2 {
		font-size: 2rem;
	}
}